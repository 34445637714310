<template>
  <div class="border-box">
    <h4 class="block-title mb-3">
      {{ $t('Checkout.ORDER_SUMMARY_TITLE') }}
    </h4>
    <table class="table cart-price-table m-0">
      <tbody>
        <tr
          v-for="(item) in orderedItems"
          :key="item.id"
        >
          <td>{{ item.title }}</td>
          <td class="text-right">{{ getProductTotal(item) | tenzerCurrency }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>{{ $t('Checkout.TOTAL') }}</th>
          <th class="text-right">{{ orderTotal | tenzerCurrency }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import utils from '@/mixins/utils'

  export default {
    name: 'OrderSummary',

    mixins: [utils],

    computed: {
      ...mapGetters({ orderedItems: 'getCartItems' }),
      orderTotal () {
        let total = 0;
        this.orderedItems.forEach( ( item ) => {
          let price = this.getProductTotal( item );
          total += Number( price );
        })
        return total;
      }
    },
    methods:{
      convertPrice ( price ) {
        price = price.replace(',', '.');
        return price;
      },
      getProductTotal ( product ){
        
        let price = product.price.replace( '€', '').trim();
        price = this.convertPrice( price );

        if( product.quantity && product.quantity > 0 ){
          price = ( product.quantity * price );
          price = parseFloat( price ) .toFixed(2);
        }
        return price;
      }
    }
  }
</script>
