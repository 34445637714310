export default {
  methods: {
    getCalculatedPrice (product) {
      return product.price * product.quantity
    },

    getTotalPrice (products) {
      const finalPrices = products.map((product) => {
        return this.getCalculatedPrice(product)
      })

      return finalPrices.reduce((a, b) => a + b, 0)
    }
  }
}
